import React from 'react'
import ContactC from '../Component/ContactComponent/Contact'
import Layout from '../Component/Layout/Layout'
import GetIntouch from '../Component/ContactComponent/GetIntouch'
import HeroSlider from '../Component/HeroSlider'

function ContactPage() {
  return (
    <Layout>
      <HeroSlider/>
        <ContactC/>
        <GetIntouch/>
    </Layout>
  )
}

export default ContactPage