import React from 'react'

function WhatWeProvide({Data}) {
  return (
<section id="feature" className="feature feature-left pt-90 pb-30">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12">
        <div className="heading heading-2 text-center mb-70">
          <h2 className="heading--title">What We Provide?</h2>
          <p className="heading--desc">
            Duis aute irure dolor in reprehed in volupted velit esse dolore
          </p>
        </div>
        {/* .heading-title end */}
      </div>
      {/* .col-md-12 end */}
    </div>
    {/* .row end */}
    <div className="row">
      {/* feature Panel #1 */}
      <div className="col-xs-12 col-sm-6 col-md-6">
        <div className="feature-panel">
          <div className="feature--icon">
            <img src="assets/images/features/icons/1.png" alt="icon img" />
          </div>
          <div className="feature--content">
            <h3>Presenting Your Property</h3>
            <p>
           {Data?.text_one}
            </p>
          </div>
        </div>
        {/* .feature-panel end */}
      </div>
      {/* .col-md-6 end */}
      {/* feature Panel #2 */}
      <div className="col-xs-12 col-sm-6 col-md-6">
        <div className="feature-panel">
          <div className="feature--icon">
            <img src="assets/images/features/icons/2.png" alt="icon img" />
          </div>
          <div className="feature--content">
            <h3>Renting or Selling</h3>
            <p>
            {Data?.text_two}
            </p>
          </div>
        </div>
        {/* .feature-panel end */}
      </div>
      {/* .col-md-6 end */}
      {/* feature Panel #3 */}
      <div className="col-xs-12 col-sm-6 col-md-6">
        <div className="feature-panel">
          <div className="feature--icon">
            <img src="assets/images/features/icons/3.png" alt="icon img" />
          </div>
          <div className="feature--content">
            <h3>Property Exchange</h3>
            <p>
            {Data?.text_three}
            </p>
          </div>
        </div>
        {/* .feature-panel end */}
      </div>
      {/* .col-md-6 end */}
      {/* feature Panel #4 */}
      <div className="col-xs-12 col-sm-6 col-md-6">
        <div className="feature-panel">
          <div className="feature--icon">
            <img src="assets/images/features/icons/4.png" alt="icon img" />
          </div>
          <div className="feature--content">
            <h3>Buying a Property</h3>
            <p>
            {Data?.text_four}
            </p>
          </div>
        </div>
        {/* .feature-panel end */}
      </div>
      {/* .col-md-6 end */}
    </div>
    {/* .row end */}
  </div>
  {/* .container end */}
</section>

  )
}

export default WhatWeProvide