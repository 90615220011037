/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  // useNavigate,
  // useNavigate,
  // useLocation,
} from "react-router-dom";
// import Signup from './Signup';
import Modal from "./Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Header() {
  // const navigate = useNavigate();

  const scrolltop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const status = queryParams.get("status");
  // console.log(status);
  // const Navigate = useNavigate();
  // const scrollToFooter = (e) => {
  //   e?.preventDefault();
  //   const footerElement = document.getElementById("properties-carousel1");
  //   if (footerElement) {
  //     footerElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  // const scrollToFooter1 = (e) => {
  //   e?.preventDefault();
  //   const footerElement = document.getElementById("properties-carousel1");
  //   if (footerElement) {
  //     footerElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  // const scrollToFooter2 = (e) => {
  //   e?.preventDefault();
  //   const footerElement = document.getElementById("properties-carousel2");
  //   if (footerElement) {
  //     footerElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const [condition, setcondition] = useState({
    login: true,
    signup: false,
    forget: false,
    reset: false,
  });

  const isLogin = useRef();
  const loginModal = () => {
    isLogin?.current?.click();
  };

  const [headerColor, setHeaderColor] = useState("transparent");
  const token = localStorage.getItem("Token");
  // const user = JSON.parse(localStorage.getItem("user"));
  // eslint-disable-next-line no-unused-vars
  let a = 0;
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 30) {
        setHeaderColor("blue");
      } else {
        setHeaderColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    if (a < 1 && !token) {
      loginModal();
      a++;
    }

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //
  const HandleLogout = () => {
    localStorage.clear();
    toast.success("Logout Sucesfully");
    window.location.reload();
  };


  return (
    <header
      id="navbar-spy"
      style={{ zIndex: 3 }}
      className="header header-1 header-transparent header-fixed"
    >
      <nav
        id="primary-menu"
        className={` ${
          headerColor === "transparent"
            ? "navbar navbar-fixed-top affix-top bg-black"
            : "navbar navbar-fixed-top affix"
        } `}
      >
        <div className="">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="logo" href="">
              {headerColor === "transparent" ? (
                <Link to={"/en-gh"}>
                  <img
                    className="logo-light"
                    style={{ width: "200px" }}
                    src="/assets/images/logo-white.png"
                    alt="Land Logo"
                  />
                </Link>
              ) : (
                <Link to={"/en-gh"}>
                  <img
                    style={{ width: "200px" }}
                    className="logo-dark"
                    src="/assets/images/logo-dark2.png"
                    alt="Land Logo"
                  />
                </Link>
              )}
            </a>
          </div>
          <div
            className="collapse navbar-collapse pull-right"
            id="navbar-collapse-1"
          >
            <ul className="nav navbar-nav nav-pos-center navbar-left">
              {/* Home Menu */}
              <li>
                <Link to={"/en-gh"} onClick={scrolltop}>
                  Home
                </Link>
              </li>
              <li>

                <Link to={"/en-gh/room"} >
                  Rooms for Rent
                </Link>
              </li>
              <li>
                <Link to={"/en-gh/apartment"} onClick={scrolltop}>
                  Apartments for Rent
                </Link>
              </li>
              <li>
                <Link to={"/en-gh/house"} onClick={scrolltop}>
                  Houses for Rent
                </Link>
              </li>
              {/* <li className="has-dropdown">
                <a
                  href="#"
                  data-toggle="dropdown"
                  onClick={() => Navigate("/listings")}
                  className="dropdown-toggle menu-item"
                >
                  Nearby Rentals{" "}
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to={"/cheaproom"}>Cheap Rooms in Accra</Link>
                  </li>
                  <li>
                    <Link to={"/room"}>Room For Rent</Link>
                  </li>
                  <li>
                    <Link to={"/apartment"}>Apartments For Rent</Link>
                  </li>
                  <li>
                    <Link to={"/house"}>Houses For rent</Link>
                  </li>
                </ul>
              </li> */}

              {/* li end */}
              {/* Pages Menu*/}
              {/* <li className="has-dropdown">
              <a
                href="#"
                data-toggle="dropdown"
                className="dropdown-toggle menu-item"
              >
                Pages
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-submenu">
                  <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                    agents
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="agents.html">All Agents</a>
                    </li>
                    <li>
                      <a href="agent-profile.html">agent profile</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                    agencies
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="agency-list.html">all agencies</a>
                    </li>
                    <li>
                      <a href="agency-profile.html">agency profile</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                    blog
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="blog.html">blog Grid</a>
                    </li>
                    <li>
                      <a href="blog-sidebar-right.html">blog Grid Right </a>
                    </li>
                    <li>
                      <a href="blog-sidebar-left.html">blog Grid Left </a>
                    </li>
                    <li>
                      <a href="blog-single.html">blog single</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="page-about.html">page about</a>
                </li>
                <li>
                  <a href="page-contact.html">page contact</a>
                </li>
                <li>
                  <a href="page-faq.html">page FAQ</a>
                </li>
                <li>
                  <a href="page-404.html">page 404</a>
                </li>
              </ul>
            </li> */}
              {/* li end */}
              {/* Profile Menu*/}
              {/* <li className="">
                <a
                  href="#"
                  data-toggle="dropdown"
                  className="dropdown-toggle menu-item"
                >
                  Profile
                </a> */}
              {/* <ul className="dropdown-menu">
                <li>
                  <a href="user-profile.html">user profile</a>
                </li>
                <li>
                  <a href="social-profile.html">social profile</a>
                </li>
                <li>
                  <a href="my-properties.html">my properties</a>
                </li>
                <li>
                  <a href="favourite-properties.html">favourite properties</a>
                </li>
                <li>
                  <Link to="/addproperty">add property</Link>
                </li>
              </ul> */}
              {/* </li> */}
              {/* li end */}
              {/* Properties Menu*/}
              {/* {user?.is_verified !== 1 ? (
                <li className="">
                  <Link to={"/detail"}>Properties</Link>
                </li>
              ) : (
                ""
              )} */}
              {/* <li>
                <Link to={"/contacts"} onClick={scrolltop}>
                  contact
                </Link>
              </li> */}
            </ul>
            <div className="module module-property pull-left">
              {token ? (
                <a
                  target="_blank"
                  className="btn"
                  onClick={() => HandleLogout()}
                >
                  Logout
                </a>
              ) : (
                <a
                  ref={isLogin}
                  data-toggle="modal"
                  data-target="#signupModule"
                  className="btn"
                  onClick={() =>
                    setcondition({
                      ...condition,
                      login: true,
                      signup: false,
                      forget: false,
                      reset: false,
                    })
                  }
                >
                  <i className="fa fa-plus" /> Login
                </a>
              )}
              <Modal
                id={"signupModule"}
                setcondition={setcondition}
                condition={condition}
              />
            </div>

            {/* Module Signup  */}
            {/* <div className="module module-login pull-left">
              {token ? (
                <a className="btn-popup" onClick={() => HandleLogout()}>
                  Logout
                </a>
              ) : (
                <a
                  ref={isLogin}
                  className="btn-popup"
                  data-toggle="modal"
                  data-target="#signupModule"
                >
                  Login
                </a>
              )}
              <Modal id={"signupModule"} />
            </div> */}

            {/* {user?.is_verified === 1 || user?.is_verified === '1'&& token && user?.w_phone ? (
              <>
                <div className="module module-property pull-left">
                  <Link to="/addproperty" className="btn">
                    <i className="fa fa-plus" /> add property
                  </Link>
                </div>
              </>
            ) : (
              ""
            )} */}

            {token && (
              <div
                className="module module-property pull-left"
                style={{ marginLeft: "10px" }}
              >
                <Link to="/profile" className="btn">
                  <i className="fa fa-user" /> View Profile
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
