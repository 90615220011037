// CookieConsent.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.css'; 

const COOKIE_NAME = 'cookieConsent';

function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get(COOKIE_NAME);
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set(COOKIE_NAME, 'accepted', { expires: 365 }); 
    setIsVisible(false);
  };

  // const handleReject = () => {
  //   Cookies.set(COOKIE_NAME, 'rejected', { expires: 365 }); 
  //   setIsVisible(false);
  //   alert("You have rejected cookies. Some features might not work properly.");
  // };

  if (!isVisible) return null;

  return (
    <div className="cookie-overlay">
      <div className="cookie-popup">
        <p className="cookie-text">
          We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
        </p>
        <div className="cookie-buttons">
          <button onClick={handleAccept} className="cookie-button">
            Accept
          </button>
          {/* <button onClick={handleReject} className="cookie-button">
            Reject
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default CookieConsent;
