import React from "react";

// import Detail from "../Component/Detail";
// import Layout from "../Component/Layout/Layout";
// import HeaderApartment from "../Header/Heade/Apartment";
import Layout from "../Layout/Layout";
import HeaderApartment from "../Header/HeaderApartment";
// import HeaderRoom from "../../Component/He";

function Apartment() {
  return (
    <Layout>
      <div className="top-res">
        <HeaderApartment />
      </div>
    </Layout>
  );
}

export default Apartment;
