/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import Map from "./Map.js";
import HeaderHello from "./HeaderHello.js";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosData } from "../Config/Axios.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { axiosInstancenew } from "../Config/AxiosNew.js";
import { toast } from "react-toastify";

function Alllisitng() {
  const { state } = useLocation();
  const [headerColor, setHeaderColor] = useState("transparent");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("Token");
  const [grid, setgrid] = useState("");

  const [data, setData] = useState([]);
  const [loader, setloader] = useState(false);
  const [isFavourite, setIsFavourite] = useState(data?.isFavourite === 1);
  const navigate = useNavigate();
  const GetData = () => {
    const Data = new FormData();
    Data.append("byDistrict", "Accra");

    axiosInstancenew
      .post("/listing-search", Data)
      .then((response) => {
        console.log("Form submitted successfully!", response.data);
        setData(response?.data?.listings);
        if (response?.data?.status) {
          // toast.success(response?.data?.message);
          // You can also set the response data to state here if needed
          // setData(response?.data?.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };
  const HandleFav = (id) => {
    const FormDat = new FormData();
    FormDat.append("listing_id", id);
    axiosData
      .post(`/add-to-favourite`, FormDat)
      .then((res) => {
        console.log(res.data);
        if (res?.data?.message === "Unsave") {
          setIsFavourite(false);
          toast.success("Unfavorite");
          GetData();
        } else {
          toast.success("favorite");
          setIsFavourite(true);
          GetData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetData();

    window.scrollTo(0, 0);
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 1700) {
        setHeaderColor("blue");
      } else {
        setHeaderColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []); //
  return (
    <>
      <div
        className="properties-split pt-0 pb-0 bg-white"
        id="properties-split"
      >
        <div className="container-fluid">
          <div className="">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="row  pt-45 properties-list listgrid">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="properties-filter clearfix">
                    <div className="select--box pull-left">
                      <label>{data?.length} Results Found</label>
                    </div>
                    <div className="view--type pull-right">
                      <a
                        className={grid === "list" ? "active" : ""}
                        href="#"
                        id="switch-list"
                        onClick={() => setgrid("list")}
                      >
                        <i className="fa fa-th-list" />
                      </a>
                      <a
                        className={grid === "grid" ? "active" : ""}
                        href="#"
                        id="switch-grid"
                        onClick={() => setgrid("grid")}
                      >
                        <i className="fa fa-th-large" />
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className={`properties properties-grid ${
                    grid === "list" ? "newhello1" : "newhello"
                  }`}
                >
                  {data.map((e) => (
                    <div key={e.id} className="col-xs-12 col-sm-12 col-md-12">
                      <div
                        className={`property-item ${
                          grid === "list" ? "listby" : ""
                        }`}
                      >
                        <div className="property--img">
                          {loader ? (
                            <Skeleton style={{ height: "200px" }} />
                          ) : (
                            <img
                              alt="property image"
                              onClick={() =>
                                navigate(`/en-gh/singlepro/${e.id}`)
                              }
                              className={`img-responsive ${
                                grid === "list" ? "h-274" : "h-200"
                              }`}
                              src={e.listing_images?.[0]?.image}
                            />
                          )}
                          {/* </a> */}
                          <span className="property--status">
                            {loader ? <Skeleton /> : "for rent"}
                          </span>
                        </div>
                        <div className="property--content">
                          <div className="property--info">
                            <h5 className="property--title">
                              {loader ? (
                                <Skeleton count={3} />
                              ) : (
                                <>
                                  <a href="#">{e.title}</a>
                                  <span
                                    className="value"
                                    onClick={() => HandleFav(e?.id)}
                                    style={{ float: "right" }}
                                  >
                                    {" "}
                                    {e?.is_favourite === 1 ? (
                                      <i
                                        className="fa fa-heart"
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ) : (
                                      <i
                                        className="fa fa-heart-o"
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                  </span>
                                </>
                              )}
                            </h5>
                            <p className="property--location">
                              <i className="fa fa-map-marker" />
                              {loader ? (
                                <Skeleton />
                              ) : (
                                `${e?.city}, ${e?.district}, ${e?.region}`
                              )}
                              <span style={{ float: "right" }}>
                                {e?.created_at}
                              </span>
                            </p>
                            <div className="flex11">
                              <p className="property--price">GH₵ {e?.price}</p>
                              <div style={{ display: "flex" }}>
                                <button className="bg-black p-xs border-none button-detail">
                                  {user?.is_verified === "1" && token ? (
                                    <a
                                      href={`tel:+${e?.host?.phone}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i
                                        className="fa fa-phone"
                                        style={{ color: "white" }}
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      href={``}
                                      onClick={(e) => {
                                        e?.preventDefault();
                                        alert(
                                          "YOU MUST BE VERIFIED TO USE THIS FEATURE. "
                                        );
                                      }}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i
                                        className="fa fa-phone"
                                        style={{ color: "white" }}
                                      />
                                    </a>
                                  )}
                                </button>
                                <button className="bg-black p-xs ml-xs border-none button-detail">
                                  {user?.is_verified === "1" && token ? (
                                    <a
                                      href={`https://wa.me/${e?.host?.w_phone}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i
                                        className="fa fa-whatsapp"
                                        style={{ color: "white" }}
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      href={``}
                                      onClick={(e) => {
                                        e?.preventDefault();
                                        alert(
                                          "YOU MUST BE VERIFIED TO USE THIS FEATURE. "
                                        );
                                      }}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i
                                        className="fa fa-whatsapp"
                                        style={{ color: "white" }}
                                      />
                                    </a>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="property--features">
                            {/* <p>{e?.description.slice(0, 83)}</p> */}
                            <div className="flex1">
                              {/* <div className="">
                                     <img
                                       className="mtt-10"
                                       src="https://dve7rykno93gs.cloudfront.net/assets4/img/garage-icon.png"
                                       alt=""
                                     />
                                     <span className="ml-sm bold font-20 text-black ">
                                       1
                                     </span>
                                   </div> */}
                              <div className="">
                                <img
                                  className="mtt-10"
                                  src="https://dve7rykno93gs.cloudfront.net/assets4/img/shower-icon.png"
                                  alt=""
                                />
                                <span className="ml-sm bold font-20 text-black ">
                                  {e?.no_of_bathroom}
                                </span>
                              </div>
                              <div className="">
                                <img
                                  className="mtt-10"
                                  src="https://dve7rykno93gs.cloudfront.net/assets4/img/bed-icon.png"
                                  alt=""
                                />
                                <span className="ml-sm bold font-20 text-black ">
                                  {e?.no_of_bedroom}
                                </span>
                              </div>
                              <div className="">
                                <i
                                  class="fa fa-home"
                                  style={{ fontSize: "20px" }}
                                ></i>
                                <span className="ml-sm bold font-20 text-black ">
                                  {e?.type}
                                </span>
                              </div>
                              {/* <div className="">
                                     <img
                                       className="mtt-10"
                                       src="https://dve7rykno93gs.cloudfront.net/assets2/images/area-icon.png"
                                       alt=""
                                     />
                                     <span className="ml-sm bold font-20 text-black ">
                                       400 m<sup>2</sup>
                                     </span>
                                   </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Alllisitng;
