import React from "react";
import Layout from "../../Component/Layout/Layout";
// import Index from '../../Component/About/AboutCom'
import AboutCom from "../../Component/About/AboutCom";
// import HeroSlider from "../../Component/HeroSlider";
import { useEffect } from "react";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useState } from "react";

// import Blog from "../../Component/Blog/Blog";

function Rental() {
  const [, setloader] = useState(false);
  const [Data, setdata] = useState([]);
  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get("/content")
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);

          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    window.scroll(0, 0);
    GetAllAdmin();
  }, []);

  return (
    <Layout>
      {/* <HeroSlider /> */}
      <AboutCom />
      <p className="container">
        <div dangerouslySetInnerHTML={{ __html: Data?.rental_guide }} />
      </p>
    </Layout>
  );
}

export default Rental;
