import React from "react";
import Layout from "../../Component/Layout/Layout";
import Hero from "../../Component/Hero";
import Alllisitng from "../../Component/Alllisitng";

export default function CheapRoom() {
  return (
    <Layout>
      <Hero />
      <Alllisitng/>
    </Layout>
  );
}
