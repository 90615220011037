// src/components/LoaderCard.js

import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoaderCard = () => {
  const arr = [0, 1, 2];
  return (
    <>
      {arr.map((e) => {
        return (
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="property-item">
              <div className="property--img">
                <Skeleton height={200} />
                <span className="property--status">
                  <Skeleton width={10} />
                </span>
              </div>
              <div className="property--content">
                <div className="property--info">
                  <h5 className="property--title">
                    <Skeleton width={300} />
                    <span className="value" style={{ float: "right" }}>
                      <Skeleton width={20} />
                    </span>
                  </h5>
                  <p className="property--location">
                    <Skeleton width={260} />
                  </p>
                  <div className="flex1">
                    <p
                      className="property--price"
                      style={{ marginTop: "-20px" }}
                    >
                      <Skeleton width={100} />
                    </p>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Skeleton width={50} height={30} />
                      <Skeleton
                        width={50}
                        height={30}
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="property--features">
                  <Skeleton count={2} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default LoaderCard;
