/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Signup.css";
import { axiosInstance } from "../Config/AxiosInstance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Forget = ({ id, setcondition, ...condition }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    setSubmitting(true);
    formData.append("email", values.email);

    axiosInstance
      .post("/forgot", formData)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          setSubmitting(false);
          toast.success(res?.data?.message);
          resetForm();
          setcondition({
            ...condition,
            login: false,
            signup: false,
            forget: false,
            reset: true,
          });
        } else {
          toast.error(res?.data?.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="mb-0">
          <div className="form-group">
            <label>Email*</label>
            <Field
              type="email"
              className="form-control"
              name="email"
              placeholder="Email Address"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-danger"
            />
          </div>

          <button
            type="submit"
            className="btn btn--primary btn--block"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Forget Password"}
          </button>
          <button
            type="button"
            className="btn btn--primary btn--block"
            onClick={() => {
              setcondition({
                login: false,
                signup: false,
                forget: false,
                reset: false,
              });
              // Hide the modal using jQuery
              window.$(`#${id}`).modal("hide");
            }}
          >
            Cancel
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Forget;
