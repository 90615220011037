import React from "react";

// import Detail from "../Component/Detail";
// import Layout from "../Component/Layout/Layout";
import HeaderRoom from "../Header/HeaderRoom";
import Layout from "../Layout/Layout";
// import HeaderRoom from "../../Component/He";

function Rooms() {
  return (
    <Layout>
      <div className="top-res">
        <HeaderRoom />
      </div>
    </Layout>
  );
}

export default Rooms;
