/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Signup.css";
import { axiosInstance } from "../Config/AxiosInstance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Reset = ({id, setcondition, ...condition }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [cshowPassword, setcShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    cpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    // code: Yup.string()
    //   .matches(/^[0-9]{10}$/, "Code must be 10 digits")
    //   .required("Code is required"),
  });

  const initialValues = {
    email: "",
    password: "",
    cpassword: "",
    code: "",
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    setSubmitting(true);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("otp", values.code);
    formData.append("password_confirmation", values.cpassword);

    axiosInstance
      .post("/reset", formData)
      .then((res) => {
        if (res?.data?.status === true) {
          setSubmitting(false);
          toast.success(res?.data?.message);
          resetForm();
          setcondition({
            ...condition,
            login: true,
            signup: false,
            forget: false,
            reset: false,
          });
        } else {
          toast.error(res?.data?.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err);
      });
  };

  const togglecPasswordVisibility = () => {
    setcShowPassword(!cshowPassword);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="mb-0">
          <div className="form-group">
            <label>Enter Code*</label>
            <Field
              type="number"
              className="form-control"
              name="code"
              placeholder="Enter Code"
            />
            <ErrorMessage name="code" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label>Email*</label>
            <Field
              type="email"
              className="form-control"
              name="email"
              placeholder="Email Address"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-danger"
            />
          </div>

          <div className="form-group password-input-group">
            <label>Password*</label>
            <Field
              type={showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              placeholder="Password"
            />
            <i
              className={`fa ${
                showPassword ? "fa-eye-slash" : "fa-eye"
              } password-toggle-icon`}
              onClick={togglePasswordVisibility}
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-danger"
            />
          </div>

          <div className="form-group password-input-group">
            <label>Confirm Password*</label>
            <Field
              type={cshowPassword ? "text" : "password"}
              className="form-control"
              name="cpassword"
              placeholder="Confirm Password"
            />
            <i
              className={`fa ${
                cshowPassword ? "fa-eye-slash" : "fa-eye"
              } password-toggle-icon`}
              onClick={togglecPasswordVisibility}
            />
            <ErrorMessage
              name="cpassword"
              component="div"
              className="text-danger"
            />
          </div>

          <button
            type="submit"
            className="btn btn--primary btn--block"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Reset"}
          </button>
          <button
            type="button"
            className="btn btn--primary btn--block"
            onClick={() => {
              setcondition({
                login: false,
                signup: false,
                forget: false,
                reset: false,
              });
              // Hide the modal using jQuery
              window.$(`#${id}`).modal("hide");
            }}
          >
            Cancel
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Reset;
