import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../Config/AxiosInstance";
import { toast } from "react-toastify";

function Modal({ id }) {
  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    message: "",
  };

  const onSubmitLogin = (values, { setSubmitting }) => {
    const formData = new FormData();

    setSubmitting(true);
    formData.append("message", values.message);

    axiosInstance
      .post("/reports", formData)
      .then((res) => {
          toast.success("Message Successfully Sent");
          window.location.reload();
      })
      .catch((err) => {
        console.error("Error in sending message", err);
        toast.error("An error occurred");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div
      className={`modal register-login-modal fade`}
      tabIndex={-1}
      role="dialog"
      id={id}
    >
      <div className={"modal-dialog modal-lg wi-400"} role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="tab-content">
                <div className={`tab-pane fade in active`} id="login">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitLogin}
                  >
                    {({ isSubmitting }) => (
                      <Form className="mb-0">
                        <div className="form-group">
                          <label>Message*</label>
                          <Field
                            as="textarea"
                            className="form-control"
                            name="message"
                            rows="5"
                            placeholder="Enter your message here"
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn--primary btn--block"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Sending..." : "Send Message"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
