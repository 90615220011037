import React from "react";

function SimpleStep() {
  return (
    <section id="feature" className="feature feature-1 text-center bg-white pb-90">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="heading heading-2 text-center mb-70">
            <h2 className="heading--title">Three Simple Steps</h2>
            <p className="heading--desc">
            to discover millions of rooms, houses, and apartments for rent.
            </p>
          </div>
          {/* .heading-title end */}
        </div>
        {/* .col-md-12 end */}
      </div>
      {/* .row end */}
      <div className="row">
        {/* feature Panel #1 */}
        <div className="col-xs-12 col-sm-4 col-md-4">
          <div className="feature-panel">
            <div className="feature--icon">
              <img src="assets/images/features/icons/5.png" alt="icon img" />
            </div>
            <div className="feature--content">
              <h3>Search For A Place To Rent</h3>
              <p>
              Simply search for a room, a house, or an apartment for rent.
              </p>
            </div>
          </div>
          {/* .feature-panel end */}
        </div>
        {/* .col-md-4 end */}
        {/* feature Panel #2 */}
        <div className="col-xs-12 col-sm-4 col-md-4">
          <div className="feature-panel">
            <div className="feature--icon">
              <img src="assets/images/features/icons/6.png" alt="icon img" />
            </div>
            <div className="feature--content">
              <h3>Select Your Favorite</h3>
              <p>
              Contact the landlord through WhatsApp, Calls or SMS and schedule tour of the rental property
              </p>
            </div>
          </div>
          {/* .feature-panel end */}
        </div>
        {/* .col-md-4 end */}
        {/* feature Panel #3 */}
        <div className="col-xs-12 col-sm-4 col-md-4">
          <div className="feature-panel">
            <div className="feature--icon">
              <img src="assets/images/features/icons/7.png" alt="icon img" />
            </div>
            <div className="feature--content">
              <h3>Take Your Key</h3>
              <p>
              Liked what you have seen? Sign the lease agreement and take your key
              </p>
            </div>
          </div>
          {/* .feature-panel end */}
        </div>
        {/* .col-md-4 end */}
      </div>
      {/* .row end */}
    </div>
    {/* .container end */}
  </section>
  
  );
}

export default SimpleStep;
