import React from 'react'

function GetIntouch() {
  return (
<section id="contact" className="contact contact-1">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-3">
        <div className="heading heading-2 mb-55">
          <h2 className="heading--title">Get In Touch</h2>
        </div>
        <div className="contact-panel">
          <h3>Address</h3>
          <p>86 Petersham town, Wardll street Australia PA 6550.</p>
        </div>
        {/* .contact-panel */}
        <div className="contact-panel">
          <h3>Phone:</h3>
          <p>(04) 491 570 110</p>
          <p>+61 525 240 310</p>
        </div>
        {/* .contact-panel */}
        <div className="contact-panel">
          <h3>Email:</h3>
          <p>contact@land.com</p>
        </div>
        {/* .contact-panel */}
      </div>
      {/* .col-md-3 end */}
      {/* .col-md-8 end */}
    </div>
    {/* .row end */}
  </div>
</section>

  )
}

export default GetIntouch