import { createContext, useState } from "react"

 export  const AuthContext = createContext()
 export default function AuthProvider({children}){
    const [filters, setFilters] = useState({
        selectedState: "",
        selectedCity: "",
        baths: "",
        cityTown: "",
        beds: "",
        price: "",
        sorts: "",
        type: "",
        rentPeriod: "",
        availability: "",
      });
    return(
        <AuthContext.Provider value={{filters,setFilters}}>
            {children}
        </AuthContext.Provider >
    )
}