import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

function ReCapture({ onCaptchaChange }) {
    const [, setCaptcha] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        if (value) {
            setCaptcha(true);
            onCaptchaChange(true);
        } else {
            setCaptcha(false);
            onCaptchaChange(false);
        }
    }

    const key1 = '6LeryiUqAAAAABZzntdl0VfjGW_mygicOfUuJxRg';

    return (
        
        <ReCAPTCHA
            sitekey={key1}
            onChange={onChange}
        />
    );
}

export default ReCapture;
