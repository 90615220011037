import React from "react";

// import Detail from "../Component/Detail";
// import Layout from "../Component/Layout/Layout";
// import HeaderApartment from "../Header/Heade/Apartment";
import Layout from "../Layout/Layout";
// import HouseCom from "./HouseCom.";
import HeaderHouse from "../Header/HeaderHouse";
// import HeaderApartment from "../Header/HeaderApartment";
// import HeaderRoom from "../../Component/He";

function House() {
  return (
    <Layout>
      <div className="top-res">
        <HeaderHouse />
      </div>
    </Layout>
  );
}

export default House;
