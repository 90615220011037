/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Signup.css";
import { axiosInstance } from "../Config/AxiosInstance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Helper function to format phone number
// const formatPhoneNumber = (value) => {
//   // Remove all non-digit characters
//   const digits = value.replace(/\D/g, '');

//   // Apply the formatting
//   if (digits.length <= 4) {
//     return digits;
//   } else if (digits.length <= 7) {
//     return `${digits.slice(0, 4)}-${digits.slice(4)}`;
//   } else {
//     return `${digits.slice(0, 4)}-${digits.slice(4, 7)}-${digits.slice(7, 10)}`;
//   }
// };

const Signup = ({ id, setcondition, ...condition }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordone, setShowPasswordone] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    // phone: Yup.string()
    //   .matches(/^\d{4}-\d{3}-\d{3}$/, "Phone number is not valid")
    //   .required("Phone Number is required"),
    phone: Yup.string()
      .matches(
        /^233\d{9}$/,
        "Phone number must start with 233 and be exactly 12 digits long"
      )
      .required("Phone number is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    terms: Yup.bool().oneOf([true], "You must accept the Terms of Service"),
  });

  const initialValues = {
    email: "",
    password: "",
    phone: "",
    terms: false,
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    setSubmitting(true);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("password_confirmation", values.password_confirmation);
    formData.append("phone", values.phone);
    formData.append("role", "user");

    axiosInstance
      .post("/signup", formData)
      .then((res) => {
        if (res?.data?.status === true) {
          setSubmitting(false);
          toast.success(res?.data?.message);
          resetForm();
          setcondition({
            ...condition,
            login: true,
            signup: false,
            forget: false,
          });
        } else {
          toast.error(res?.data?.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err);
        setSubmitting(false);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilityone = () => {
    setShowPasswordone(!showPasswordone);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="mb-0">
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <Field
              type="text"
              id="phone"
              className="form-control"
              name="phone"
              placeholder="Enter Number"
              maxLength="12"
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, ""); // Remove any non-digit characters
                if (!value.startsWith("233")) {
                  // eslint-disable-next-line prefer-template
                  value = "233" + value.slice(3); // Ensure the value always starts with '233'
                }
                if (value.length > 12) {
                  value = value.slice(0, 12); // Ensure the value does not exceed 12 characters
                }
                setFieldValue("phone", value); // Update the value
              }}
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-danger"
            />
          </div>

          <div className="form-group">
            <label>Email*</label>
            <Field
              type="email"
              className="form-control"
              name="email"
              placeholder="Email Address"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-danger"
            />
          </div>

          <div className="form-group password-input-group">
            <label>Password*</label>
            <Field
              type={showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              placeholder="Password"
            />
            <i
              className={`fa ${
                showPassword ? "fa-eye-slash" : "fa-eye"
              } password-toggle-icon`}
              onClick={togglePasswordVisibility}
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-danger"
            />
          </div>

          <div className="form-group password-input-group">
            <label>Confirm Password*</label>
            <Field
              type={showPasswordone ? "text" : "password"}
              className="form-control"
              name="password_confirmation"
              placeholder="Confirm Password"
            />
            <i
              className={`fa ${
                showPasswordone ? "fa-eye-slash" : "fa-eye"
              } password-toggle-icon`}
              onClick={togglePasswordVisibilityone}
            />
            <ErrorMessage
              name="password_confirmation"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="input-checkbox">
            <label className="label-checkbox">
              <span>
                I have read and agree to the{" "}
                <a href="https://www.company.rentisready.com/terms.php">
                  Terms of Service
                </a>
              </span>
              <Field type="checkbox" name="terms" />
              <span className="check-indicator" />
            </label>
            <ErrorMessage
              name="terms"
              component="div"
              className="text-danger"
            />
          </div>

          <button
            type="submit"
            className="btn btn--primary btn--block"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Register"}
          </button>
          <button
            type="button"
            className="btn btn--primary btn--block"
            onClick={() => {
              setcondition({
                login: false,
                signup: false,
                forget: false,
                reset: false,
              });
              // Hide the modal using jQuery
              window.$(`#${id}`).modal("hide");
            }}
          >
            Cancel
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Signup;
