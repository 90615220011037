import React from "react";
import Layout from "../../Component/Layout/Layout";
import Hero from "../../Component/Hero";
// import Alllisitng from "../../Component/Alllisitng";
import Feature from "../../Component/Feature/Feature";

export default function Features() {
  return (
    <Layout>
      <Hero />
      <Feature/>
    </Layout>
  );
}
