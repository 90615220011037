import React from "react";
import Layout from "../../Component/Layout/Layout";
// import Index from '../../Component/About/AboutCom'
import AboutCom from "../../Component/About/AboutCom";
// import HeroSlider from "../../Component/HeroSlider";
import { useEffect } from "react";
import Blog from "../../Component/Blog/Blog";

function Index() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Layout>
      {/* <HeroSlider /> */}
      <AboutCom />
      <Blog />
    </Layout>
  );
}

export default Index;
