import React from 'react'
import FavoriteCom from '../../Component/Favorite/Favorite'
import Layout from '../../Component/Layout/Layout'
import SocialSidebar from '../../Component/SocialSidebar'

function Favorite() {
  return (
    <div>
<Layout>



      <section
        id="user-profile"
        className="user-profile"
        style={{ marginTop: "0px" }}
      >
        <div className="container">
          <div className="row">
            <SocialSidebar />

            <div className="col-xs-12 col-sm-12 col-md-8">
              <FavoriteCom/>
            </div>
          </div>
        </div>
      </section>
      </Layout>
    </div>
  )
}

export default Favorite